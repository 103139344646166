import TablePage from '../../../../../components/table_page';

export default {
  name: 'attendance_finish_list',
  extends: TablePage,
  data() {
    return {};
  },
  methods: {

  },
  created() {
    this.getConfigList('attendance_finish_list');
  },

};
